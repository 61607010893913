import UAParser from 'ua-parser-js';

import { parseUrlParams } from 'core/utils/url-sync';
import { doRedirectToBrowser, REDIRECT_TO_BROWSER } from 'core/utils/redirect-to-browser';

import { render } from './render';

let paths = window.location.pathname.split('/');

let promiseApp;

switch (paths[1]) {
  case 'terminate':
    promiseApp = import('./screens/terminate').then(r => r.default);
    break;
  case 'manage':
    promiseApp = import('./screens/manage').then(r => r.default);
    break;
  case 'quiz-book':
    promiseApp = import('./screens/quiz/book').then(r => r.default);
    break;
  case 'summary-book':
    promiseApp = import('./screens/subscription/book').then(r => r.default);
    break;
  case 'astrologer-gen-link':
    promiseApp = import('./screens/astrologer-gen-link').then(r => r.default);
    break;
  case 'my-account':
    promiseApp = import('./screens/my-account/routing').then(r => r.default);
    break;
  case 'asb':
    switch (paths[2]) {
      case 'paypal':
        promiseApp = import('./screens/asb/paypal').then(r => r.default);
        break;
      case 'subscription':
        promiseApp = import('./screens/asb/subscription').then(r => r.default);
        break;
      case 'reactivate':
        promiseApp = import('./screens/asb/reactivate').then(r => r.default);
        break;
      case 'one-time-payment':
        promiseApp = import('./screens/asb/one-time-payment').then(r => r.default);
        break;
      case 'one-time-payment-web-view':
        promiseApp = import('./screens/asb/one-time-web-view').then(r => r.default);
        break;
      case 'pdf-reports':
        promiseApp = import('./screens/asb/pdf-reports').then(r => r.default);
        break;
      case 'birth-chart-report':
        promiseApp = import('./screens/asb/improve-reports').then(r => r.default);
        break;
      case 'plans':
        promiseApp = import('./screens/asb/plans').then(r => r.default);
        break;
      case 'astrologers-packs':
        promiseApp = import('./screens/asb/astrologers-packs').then(r => r.default);
        break;
      default:
        throw Error(`Invalid ASB url ${window.location.pathname}`);
    }
    break;
  default:
    promiseApp = import('./screens/quiz').then(r => r.default);
    break;
}

parseUrlParams()
  .catch(() => { })
  .then(() => {
    const r = new UAParser().getResult();
    if (r?.browser?.name === 'Instagram' && r.os?.name === 'iOS' && r?.os?.version && r?.os?.version >= '17') {
      doRedirectToBrowser(REDIRECT_TO_BROWSER.ENABLED);
    }
    else {
      promiseApp.then(render);
    }
  });
