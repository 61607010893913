import { AdvisorCardData } from "./types";

export const isSmallScreen = window.innerWidth <= 375 || window.innerHeight <= 667;

export enum AdvisorsNameIds {
  ANNABELLE = "annabelle",
  ASTRID = "astrid",
  AURELIA = "aurelia",
  CHARITA = "charita",
  DIANA = "diana",
  ELLA = "ella_louise",
  ESMERALDA = 'esmeralda',
  LUNA = "luna",
  YASMIN = "yasmin",
  ZANIAH = "zaniah",
}

export enum SkillsIds {
  LIFE_PATH = "life_path",
  RELATIONSHIPS = "relationships",
  CAREER = "career",
  FAMILY = "family",
  FINANCES = "finances",
  HEALTH = "health",
}

const bestAdvisorList: AdvisorsNameIds[] = [AdvisorsNameIds.ASTRID, AdvisorsNameIds.AURELIA, AdvisorsNameIds.CHARITA, AdvisorsNameIds.LUNA, AdvisorsNameIds.ZANIAH];
const advisorOnlineList: AdvisorsNameIds[] = [AdvisorsNameIds.ANNABELLE, AdvisorsNameIds.DIANA, AdvisorsNameIds.ELLA, AdvisorsNameIds.ESMERALDA, AdvisorsNameIds.YASMIN];

export const advisorsData: AdvisorCardData[] = [
  {
    id: AdvisorsNameIds.ASTRID,
    astrologer_id: 2,
    name: "Astrid",
    experience: 8,
    rating: 4.8,
    skills: [SkillsIds.RELATIONSHIPS, SkillsIds.FAMILY, SkillsIds.LIFE_PATH],
    reviews: 139,
  },
  {
    id: AdvisorsNameIds.AURELIA,
    astrologer_id: 3,
    name: "Aurelia",
    experience: 9,
    rating: 5,
    skills: [SkillsIds.LIFE_PATH, SkillsIds.CAREER, SkillsIds.HEALTH],
    reviews: 131,
  },
  {
    id: AdvisorsNameIds.CHARITA,
    astrologer_id: 1,
    name: "Charita",
    experience: 15,
    rating: 4.9,
    skills: [SkillsIds.RELATIONSHIPS, SkillsIds.HEALTH, SkillsIds.FINANCES],
    reviews: 166,
  },
  {
    id: AdvisorsNameIds.LUNA,
    astrologer_id: 5,
    name: "Luna",
    experience: 25,
    rating: 4.7,
    skills: [SkillsIds.FINANCES, SkillsIds.CAREER, SkillsIds.LIFE_PATH],
    reviews: 531,
  },
  {
    id: AdvisorsNameIds.ZANIAH,
    astrologer_id: 4,
    name: "Zaniah",
    experience: 10,
    rating: 4.9,
    skills: [SkillsIds.LIFE_PATH, SkillsIds.CAREER, SkillsIds.RELATIONSHIPS],
    reviews: 333,
  },
  {
    id: AdvisorsNameIds.ESMERALDA,
    astrologer_id: 18,
    name: "Esmeralda",
    experience: 33,
    rating: 4.9,
    skills: [SkillsIds.LIFE_PATH, SkillsIds.CAREER, SkillsIds.HEALTH],
    reviews: 163,
  },
  {
    id: AdvisorsNameIds.ELLA,
    astrologer_id: 22,
    name: "Ella-Louise",
    experience: 21,
    rating: 4.7,
    skills: [SkillsIds.RELATIONSHIPS, SkillsIds.FAMILY, SkillsIds.LIFE_PATH],
    reviews: 252,
  },
  {
    id: AdvisorsNameIds.DIANA,
    astrologer_id: 15,
    name: "Diana",
    experience: 9,
    rating: 4.8,
    skills: [SkillsIds.CAREER, SkillsIds.FAMILY, SkillsIds.FINANCES],
    reviews: 226,
  },
  {
    id: AdvisorsNameIds.ANNABELLE,
    astrologer_id: 17,
    name: "Annabelle",
    experience: 11,
    rating: 4.7,
    skills: [SkillsIds.FINANCES, SkillsIds.CAREER, SkillsIds.LIFE_PATH],
    reviews: 107,
  },
  {
    id: AdvisorsNameIds.YASMIN,
    astrologer_id: 16,
    name: "Yasmin",
    experience: 24,
    rating: 4.7,
    skills: [SkillsIds.RELATIONSHIPS, SkillsIds.FAMILY, SkillsIds.LIFE_PATH],
    reviews: 122,
  }
];

export const bestAdvisorsData: AdvisorCardData[] = advisorsData.filter(advisor => bestAdvisorList.includes(advisor.id));
export const advisorsOnlineData: AdvisorCardData[] = advisorsData.filter(advisor => advisorOnlineList.includes(advisor.id));