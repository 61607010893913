import { Currency, } from '@web-solutions/react-billing/constants';
import { LinkTarget } from '@web-solutions/module-attribution';

import type { BaseReportViewType, DefaultUpsaleOfferType, BaseUpsalesRemoteContentParams } from '@web-solutions/core/interfaces/upsaleOffers'
import type { ExtendedWithMultiCurrencies } from '@web-solutions/core/interfaces/currencies'
import { CoreSummarySection } from '@web-solutions/core/summary/types';

import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  type Nullable,
  type LString,
  type RemoteConfig as CoreRemoteConfig,
  BaseOfferProductTypes,
  CurrencyConfig,
} from 'core/constants/remote-config';
import { InterButtonConfig } from 'core/payment/constants';

import { type QuizNameKeys, QUIZ_NAMES } from 'src/constants/screens';

import type {
  AstrologerMinutesPage,
  AstrologerPromoBlock,
  CompatibilityOffer,
  CompatibilityShipping,
  EmailPage,
  GenderPageWithProtectBadges,
  UpsalesBasket,
  WelcomePageWithImage,
  WelcomePopularQuestions,
  ReportLoader,
  SelectPlanByTrial,
} from 'src/types/remote-config'
import { CompatibilityOfferMode } from 'src/types/remote-config'
import type { ChatFlow } from 'src/screens/chat-flow/types';

import { OfferProductId } from './guides';
import { QuizNamesValues, AfterPurchaseRoutes } from './screens';

export enum SummarySection {
  ASTROLOGER_BONUS = 'astrologer_bonus',
  ADVISORS_GIFT = 'advisors_gift',
  HEADER = 'header',
  RELSPIN_HEADER = 'relspin_header',
  ADVISORS_RELSPIN_HEADER = 'advisors_relspin_header',
  LIGHT_THEME_HEADER = 'light_theme_header',
  WITCHES_HEADER = 'witches_header',
  EXPERTS = 'experts',
  PERSONALISED_PLAN = 'personalised_plan',
  FEEDBACK_LIST = 'feedback_list',
  REVIEWS = 'reviews',
  FOOTNOTE = 'footnote',
  MONEY_BACK = 'moneyback',
  PLAN_READY = 'plan_ready',
  HANDS = 'hands',
  PR_OVERVIEW = 'pr_overview',
  ADVISORS_PR_OVERVIEW = 'advisors_pr_overview',
  ZODIAC_COMPATIBILITY = 'zodiac_compatibility',
  BEST_MATCHES = 'best_matches',
  COMPATIBILITY_REPORT = 'compatibility_report',
  ADVISORS_COMPATIBILITY_REPORT = 'advisors_compatibility_report',
  BIGGEST_QUESTION = 'biggest_question',
  USERS_JOINED = 'users_joined',
  SOULMATE_SHORT = 'soulmate_short',
  SOULMATE_LONG = 'soulmate_long',
  SOULMATE_EXAMPLES = 'soulmate_examples',

  CARTOGRAPHY_SHORT = 'cartography_short',
  CARTOGRAPHY_LONG = 'cartography_long',
  CARTOGRAPHY_WITH_PALM = 'cartography_with_palm',

  HAT_HEADER = 'hat_header',
  COMPATIBILITY_INTRO = 'compatibility_intro',
  BIRTH_CHART_INTRO = 'birth_chart_intro',
  PALM_READING_INTRO = 'palm_reading_intro',
  BIRTH_CHART_BOOK = 'birth_chart_book',
  COMPATIBILITY_ANALYSIS = 'compatibility_analysis',
  PALM_COMPATIBILITY_ANALYSIS = 'palm_compatibility_analysis',
  BIRTH_CHART_ACCESS = 'birth_chart_access',
  COMPATIBILITY_ACCESS = 'compatibility_access',
  PALM_ACCESS = 'palm_access',
  COMPATIBILITY_REVIEWS = 'compatibility_reviews',
  BIRTH_CHART_REVIEWS = 'birth_chart_reviews',
  PALM_REVIEWS = 'palm_reviews',
  ADVISORS_REVIEWS = 'advisors_reviews',
  ADVISORS_EXCLUSIVE_CHATS = 'advisors_exclusive_chats',
  BEST_ADVISORS = 'best_advisors',
  ADVISORS_ONLINE = 'advisors_online',

  TAROT_PREDICTION = 'tarot_prediction',
  WELCOME_CONTENT = 'welcome_content',

  WITCH_POWER_LONG = 'witch_power_long',
  WITCH_POWER_SHORT = 'witch_power_short',
}

export enum SuccessPageType {
  GUIDE = 'guide',
  THANK = 'thank'
}
export enum ThankSuccessPageType {
  ONE_BTN = 'one_btn',
  TWO_BTN = 'two_btn',
  STORE_BTN = 'store_btn'
}

export enum ModeSocialAuth {
  DISABLED = 'disabled',
  ABOVE = 'above',
  BELOW = 'below',
}

type TrialPriceOption = {
  price: number,
  isDefault: boolean
}

export type OfferProductTypes = BaseOfferProductTypes<OfferProductId>

type UpsalesRemoteContentParams = BaseUpsalesRemoteContentParams<'blank'>

export type UltraPackOffer = {
  title: LString,
  subTitle: LString,
  type: 'reports' | 'list' | 'reportsV2' | 'listV2',
  button: LString,
  skipButtonText: LString,
  product: Omit<OfferProductTypes, 'id'>,
  autoRedirectEnabled: boolean,
  successModalEnabled: boolean,
} & UpsalesRemoteContentParams

type UpsaleOffer = DefaultUpsaleOfferType<Omit<OfferProductTypes, 'id'>, BaseReportViewType | 'TITLE_UNDER_IMAGE'>

export type SingleUpsaleOffer = UpsaleOffer & UpsalesRemoteContentParams & { promocode?: string }

export interface PackCurrencyConfig extends CurrencyConfig {
  saveAmount: string
}

export type AstrologerPack = ExtendedWithMultiCurrencies<{
  title: LString;
  minutesAmount: number;
  notActivePrice: string;
  activePrice: LString;
  freeMinsAmount: number;
  saveAmount: string;
  currency: Currency;
  price: number;
  mostPopular: boolean;
  discount: string;
}, PackCurrencyConfig>

export interface SingleAstrologerPackOffer extends UpsalesRemoteContentParams {
  pack: AstrologerPack,
  title: LString,
  subtitle: LString,
  btnText: LString,
  priceText: LString,
  autoRedirectEnabled: boolean,
  successModalEnabled: boolean,
  view: 'TITLE_UNDER_IMAGE' | 'WITH_SLIDER',
  imageType?: 'texting' | 'cards'
}


export type AfterPurchaseFlow = AfterPurchaseRoutes;

export type Flow = QuizNamesValues

export enum AstrologersPacksType {
  PACKS = 'packs',
  OFFER = 'offer',
  BENEFITS = 'benefits'
}

type AstrologerConsultationPacks = {
  title: LString;
  subtitle: LString;
  btnText: LString;
  packType: AstrologersPacksType.OFFER | AstrologersPacksType.PACKS;
  footnoteType: 'refund' | 'oneclick' | '';
  footnoteText: LString;
  withCloseIcon: boolean;
  defaultProductId: number;
  autoRedirectEnabled: boolean;
  errorPopupWithPayPal: boolean;
  showOfferBeforePurchase: boolean;
  viewType: 'freeMins' | 'save';
  badgeColor: 'green' | 'purple';
  packs: AstrologerPack[];
  successModalEnabled: boolean;
  autoRenew: {
    enabled: boolean,
    defaultValue: boolean,
  }
}

export interface RemoteConfig extends CoreRemoteConfig<Flow[number], ChatFlow> {
  trafficSource: string,

  isWelcomeNumerologyMailsModified: boolean,

  isFaceReadingSkipAvailable: boolean,
  isFaceReadingUploadPhotoAvailable: boolean,
  isFaceReadingBackAvailable: boolean,
  isFaceReadingApproveAvailable: boolean,

  isQuizTaroCardsWithTopTexts: boolean,

  selectPlanByTrial: SelectPlanByTrial,

  reportLoader: ReportLoader,

  giftBoxTitle: {
    title: LString,
    openedTitle: LString,
  },

  astrologerMinutesPage: AstrologerMinutesPage,
  astrologerMinutesPopupPage: AstrologerMinutesPage,

  upsalesBasket: UpsalesBasket;

  progressMode: 'default' | 'with-percents' | 'with-percents-v2' | 'colored-checkpoints'
  trials: TrialPriceOption[],

  presummaryStack: Array<SummarySection | 'reserved_price'>,
  presummaryButtons: Array<InterButtonConfig<SummarySection>>,
  landingStack: Array<SummarySection | CoreSummarySection>,
  landingButtons: Array<InterButtonConfig<SummarySection>>,

  upsaleEmailTransactionDetails: boolean,
  redirectToAppWithoutSubscription: {
    enabled: boolean,
    autoRedirect: boolean,
    method: 'landing' | 'after_quiz',
  },
  showCongratsAstrologerBonus: boolean,
  ultraPackOffer: UltraPackOffer,
  astrologerPackSpecialOffer: {
    enabled: boolean,
    pack: AstrologerPack,
    btnTitle: LString,
  },
  asbPlans: {
    title: LString,
    subtitle: LString,
    withHeader: boolean,
    buttonText: LString,
    backEnabled: boolean,
    firstPurchaseSuccessModalEnabled: boolean,
  },
  [QUIZ_NAMES.COMMON_GENDER_PROTECT_BADGES]: GenderPageWithProtectBadges,
  [QUIZ_NAMES.QUIZ_WELCOME_ASTROLOGER]: WelcomePageWithImage,
  [QUIZ_NAMES.QUIZ_WELCOME_POPULAR_QUESTIONS]: WelcomePopularQuestions,
  astrologerPromoBlock: AstrologerPromoBlock,
  summaryHeaderTitle: LString,
  emailPage: EmailPage,
  isDisclaimer: boolean,
  welcomePage: {
    title: LString,
    subtitle: LString,
    button_title: LString,
    isSignInAvailable: boolean,
    withGender: boolean,
  },
  birthPlacePage: {
    required: boolean,
    suggestCurrentPlace: boolean,
  }
  genderPageTitle: LString,
  withOnlyEngFootnote: boolean,
  magicDurationLoad: number,
  reportLoaderAutoRedirect: boolean,
  afterPurchaseFlow: AfterPurchaseFlow,
  isSuccessUpsalesModalEnabled: boolean,

  cameraInitTimeout: number,
  isPalmDisclaimerVisible: boolean,
  prWelcomeMode: 'default' | 'witches'
  palmSkipNextScreenName?: Extract<QuizNameKeys, 'QUIZ_REPORT_LOADER' | 'QUIZ_MAGIC'> | '',

  successPageType: SuccessPageType,
  successPageContent: Record<'title' | 'subtitle', LString>
  successPageConfig: Record<'openDownloadAppTab', boolean>
  thankSuccessPageType: ThankSuccessPageType,
  redirectToAppFromSuccessTime: number | null,

  singleAstrologerPackOffer: SingleAstrologerPackOffer,
  astrologerConsultationPacks: AstrologerConsultationPacks,
  astrologerConsultationPacksBenefits: Omit<AstrologerConsultationPacks, 'packType'> & {
    discount: number;
    packType: AstrologersPacksType.BENEFITS,
  },

  modeSocialAuth: ModeSocialAuth,
  showMailCheckbox: boolean,
  pdfOffer: {
    title: LString,
    text: LString,
    button: LString,
    activeOfferId: OfferProductId | null,
    successModalEnabled: boolean,
    autoRedirectEnabled: boolean,
    products: OfferProductTypes[],
  },
  compatibilityOffer: SingleUpsaleOffer & CompatibilityOffer,
  compatibilityHardcover: {
    title: LString,
    submitButtonText: LString,
    skipButtonEnabled: boolean,
  },

  compatibilityShipping: CompatibilityShipping,

  birthChartOffer: SingleUpsaleOffer,
  numerologyAnalysisOffer: SingleUpsaleOffer,
  secondPdfOffer: {
    title: LString,
    text: LString,
    button: string,
    enabled: boolean,
    activeOfferId: OfferProductId | null,
    products: OfferProductTypes[]
  }

  redirectTerminateToManage: boolean,

  quizSurvey: {
    link: string,
    enableClose: boolean,
  }
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,

  trafficSource: '',

  isQuizTaroCardsWithTopTexts: false,

  isWelcomeNumerologyMailsModified: false,

  isFaceReadingSkipAvailable: false,
  isFaceReadingUploadPhotoAvailable: true,
  isFaceReadingBackAvailable: true,
  isFaceReadingApproveAvailable: true,
  giftBoxTitle: {
    title: '',
    openedTitle: ''
  },

  selectPlanByTrial: {
    title: '',
    card: {
      isTitleShown: true
    },
    buttonTitle: ''
  },

  reportLoader: {
    mode: 'default'
  },

  astrologerMinutesPage: {
    buttonText: { en: '' }
  },
  astrologerMinutesPopupPage: {
    buttonText: { en: '' }
  },

  upsalesBasket: {
    title: '',
    paymentInfo: {
      title: '',
      withGift: true,
    },
    actionButtons: {
      submitButtonText: '',
      skipButtonText: '',
      withPrices: true,
      withSkipButton: true,
    }
  },

  progressMode: 'default',

  presummaryStack: [],
  presummaryButtons: [],
  landingStack: [],
  landingButtons: [],
  upsaleEmailTransactionDetails: true,
  redirectToAppWithoutSubscription: {
    enabled: false,
    method: 'after_quiz',
    autoRedirect: false,
  },
  showCongratsAstrologerBonus: false,
  trials: [],
  linkTarget: LinkTarget.WEB,
  QUIZ_WELCOME_ASTROLOGER: {
    title: { en: "" },
    subtitle: { en: "" },
    buttonTitle: { en: "" },
  },
  QUIZ_WELCOME_POPULAR_QUESTIONS: {
    title: { en: "" },
    buttonTitle: { en: "" },
  },
  COMMON_GENDER_PROTECT_BADGES: {
    title: { en: "" },
    isProtectBadgesVisible: true
  },
  astrologerPromoBlock: {
    isVisible: true,
    minutes: 5
  },
  astrologerPackSpecialOffer: {
    enabled: false,
    pack: {
      title: '',
      minutesAmount: 10,
      discount: '',
      activePrice: '$2.99',
      notActivePrice: '$9.99',
      freeMinsAmount: 0,
      saveAmount: '',
      currency: 'USD',
      price: 9.99,
      mostPopular: false,
    },
    btnTitle: '',
  },
  ultraPackOffer: {
    title: "",
    subTitle: "",
    type: 'reports',
    button: '',
    skipButtonText: '',
    autoRedirectEnabled: false,
    successModalEnabled: true,
    product: {
      title: 'test',
      subTitle: '24324',
      notActivePrice: '40',
      activePrice: '55',
      discount: '25',
      price: 100,
      currency: 'USD',
    },
    lastOption: 'test',
    offerPricesLocation: 'independently',
    activePriceFontSize: 15,
    notActivePriceFontSize: 15
  },
  withOnlyEngFootnote: false,
  singleAstrologerPackOffer: {
    pack: {
      title: '30 mins',
      minutesAmount: 30,
      discount: '',
      activePrice: '$49.99',
      notActivePrice: '$99.99',
      freeMinsAmount: 15,
      saveAmount: '$50',
      currency: 'USD',
      price: 49.99,
      mostPopular: false,
    },
    autoRedirectEnabled: false,
    priceText: '',
    title: '',
    subtitle: '',
    btnText: '',
    successModalEnabled: true,
    view: 'TITLE_UNDER_IMAGE'
  },
  astrologerConsultationPacks: {
    title: '',
    subtitle: '',
    btnText: '',
    footnoteType: '',
    packType: AstrologersPacksType.PACKS,
    autoRedirectEnabled: false,
    errorPopupWithPayPal: false,
    footnoteText: '',
    withCloseIcon: false,
    showOfferBeforePurchase: false,
    viewType: 'save',
    badgeColor: 'green',
    defaultProductId: 9,
    successModalEnabled: true,
    autoRenew: {
      enabled: false,
      defaultValue: false,
    },
    packs: [
      {
        title: '3 mins',
        minutesAmount: 3,
        activePrice: '$9.99',
        notActivePrice: '$22.00',
        discount: '',
        freeMinsAmount: 0,
        saveAmount: '',
        currency: 'USD',
        price: 9.99,
        mostPopular: false,
      },
      {
        title: '9 mins',
        minutesAmount: 9,
        activePrice: '$19.99',
        notActivePrice: '$29.99',
        discount: '',
        freeMinsAmount: 3,
        saveAmount: '$10',
        currency: 'USD',
        price: 19.99,
        mostPopular: true,
      },
      {
        title: '30 mins',
        minutesAmount: 30,
        activePrice: '$49.99',
        notActivePrice: '$99.99',
        discount: '',
        freeMinsAmount: 15,
        saveAmount: '$50',
        currency: 'USD',
        price: 49.99,
        mostPopular: false,
      },
      {
        title: '60 mins',
        minutesAmount: 60,
        activePrice: '$79.99',
        notActivePrice: '$198.99',
        freeMinsAmount: 36,
        discount: '',
        saveAmount: '$119',
        currency: 'USD',
        price: 79.99,
        mostPopular: false,
      },
    ],
  },
  astrologerConsultationPacksBenefits: {
    title: '',
    subtitle: '',
    btnText: '',
    packType: AstrologersPacksType.BENEFITS,
    footnoteType: '',
    autoRedirectEnabled: false,
    showOfferBeforePurchase: false,
    errorPopupWithPayPal: false,
    footnoteText: '',
    withCloseIcon: false,
    viewType: 'freeMins',
    badgeColor: 'green',
    defaultProductId: 9,
    successModalEnabled: true,
    discount: 50,
    autoRenew: {
      enabled: false,
      defaultValue: false,
    },
    packs: [
      {
        title: '3 mins',
        minutesAmount: 3,
        activePrice: '$9.99',
        notActivePrice: '$22.00',
        discount: '',
        freeMinsAmount: 0,
        saveAmount: '',
        currency: 'USD',
        price: 9.99,
        mostPopular: false,
      },
      {
        title: '9 mins',
        minutesAmount: 9,
        discount: '',
        activePrice: '$19.99',
        notActivePrice: '$29.99',
        freeMinsAmount: 3,
        saveAmount: '$10',
        currency: 'USD',
        price: 19.99,
        mostPopular: true,
      },
      {
        title: '30 mins',
        minutesAmount: 30,
        discount: '',
        activePrice: '$49.99',
        notActivePrice: '$99.99',
        freeMinsAmount: 15,
        saveAmount: '$50',
        currency: 'USD',
        price: 49.99,
        mostPopular: false,
      },
      {
        title: '60 mins',
        minutesAmount: 60,
        discount: '',
        activePrice: '$79.99',
        notActivePrice: '$198.99',
        freeMinsAmount: 36,
        saveAmount: '$119',
        currency: 'USD',
        price: 79.99,
        mostPopular: false,
      },
    ],
  },
  asbPlans: {
    title: '',
    subtitle: '',
    withHeader: true,
    buttonText: '',
    backEnabled: true,
    firstPurchaseSuccessModalEnabled: true,
  },

  summaryHeaderTitle: { en: '' },

  emailPage: {
    viewMode: 'default',
    title: { en: '' },
    blurComponents: ['QUIZ_ASTROLOGER_MINUTES_POPUP']
  },
  isDisclaimer: false,
  welcomePage: {
    title: '',
    subtitle: '',
    button_title: '',
    isSignInAvailable: false,
    withGender: false,
  },
  birthPlacePage: {
    required: true,
    suggestCurrentPlace: false,
  },
  genderPageTitle: { en: '', de: '', es: '', fr: '', pt: '' },
  afterPurchaseFlow: ['GUIDES', 'COMPATIBILITY', 'BIRTH_CHART', 'ASTROLOGERS_SINGLE_PACK', 'ASTROLOGERS_PACKS'],
  isSuccessUpsalesModalEnabled: true,
  magicDurationLoad: 8000,
  flow: [],
  reportLoaderAutoRedirect: true,

  cameraInitTimeout: 4000,
  prWelcomeMode: 'default',
  isPalmDisclaimerVisible: false,
  palmSkipNextScreenName: '',

  successPageType: SuccessPageType.GUIDE,
  successPageContent: {
    title: '',
    subtitle: ''
  },
  successPageConfig: {
    openDownloadAppTab: true
  },
  thankSuccessPageType: ThankSuccessPageType.TWO_BTN,
  redirectToAppFromSuccessTime: null,

  modeSocialAuth: ModeSocialAuth.ABOVE,
  showMailCheckbox: true,
  isOneClickFootnote: true,
  compatibilityOffer: {
    mode: CompatibilityOfferMode.DEFAULT,
    landing: {
      title: '',
      activePriceText: '',
      skipButtonText: '',
      submitButtonText: '',
      fixedPriceVisible: true,
      fixedSubmitButtonText: '',
      fixedSkipButtonText: '',
      withSkipButton: true
    },
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
  compatibilityHardcover: {
    title: '',
    submitButtonText: '',
    skipButtonEnabled: true,
  },

  compatibilityShipping: {
    availableShippingCountries: [],
    details: {
      buttonTitle: ''
    }
  },

  birthChartOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
  numerologyAnalysisOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
  pdfOffer: {
    title: '',
    text: '',
    button: '',
    activeOfferId: 'numerology_analysis',
    successModalEnabled: true,
    autoRedirectEnabled: false,
    products: [],
  },
  secondPdfOffer: {
    title: '',
    text: '',
    button: '',
    activeOfferId: null,
    enabled: false,
    products: []
  },

  redirectTerminateToManage: false,

  quizSurvey: {
    link: '',
    enableClose: false,
  },
  chatFlow: {
    flow: [],
    answersStyle: 'default',
    welcomeAnswerType: 'none',
    symbolsPerSecond: 8,
    palmEnabled: true,
    redirectToSummary: false,
    nameStepEnabled: true,
    buttonText: '',
    branchingMode: {
      isLongRelationshipFlow: true
    }
  },
};

export type FullConfig = Nullable<Partial<RemoteConfig>>;
